import {
  mdiPlus, mdiDeleteOutline, mdiCheck, mdiPencilOutline, mdiInformationOutline,
} from '@mdi/js'
import { ref, watch, onMounted } from '@vue/composition-api'
import { i18n } from '@/plugins/i18n'
import courseSet from '@/api/courseSet/courseSet'
import courseGroup from '@/api/courseManagement/courseGroup'
import basicStatus from '@/@fake-db/data/basicStatus.json'
import footerDataTables from '@/components/basicComponents/footerDataTables.json'
import footerDataTablesEN from '@/components/basicComponents/footerDataTablesEN.json'

export default function useCourseSetList() {
  const columns = ref([
    {
      text: '#',
      align: 'start',
      value: 'id',
      width: 70,
    },
    {
      text: i18n.t('code'),
      value: 'courseset_code',
      width: 120,
    },
    {
      text: i18n.t('name'),
      value: 'courseset_name',
    },
    {
      text: i18n.t('price'),
      value: 'courseset_price',
      align: 'end',
    }, {
      text: i18n.t('status'),
      value: 'courseset_active_id',
      align: 'center',
    },
    {
      text: i18n.t('option'),
      value: 'actions',
      sortable: false,
      align: 'center',
    },
  ])
  const dataTableList = ref([])
  const options = ref({})
  const totalDataTableList = ref(0)
  const footer = ref(i18n.locale == 'en' ? footerDataTablesEN : footerDataTables)
  const loading = ref(false)
  const totalPage = ref(0)
  const segmentId = ref(1)
  const searchtext = ref('')
  const coursegroup_id = ref('')
  const coursegroup_list = ref([])
  const statusList = ref(basicStatus.data)
  const course_status_id = ref(1)
  const isAddNewCourseSet = ref(false)
  const isEditCourseSet = ref(false)
  const isUpdateStatus = ref(false)
  const dataEdit = ref({})

  onMounted(() => {
    fetchDataTable()
  })

  const
    fetchDataTable = () => {
      loading.value = true
      courseSet.getCourseSetList({
        searchtext: searchtext.value,
        courseset_active_id: course_status_id.value,
        active_page: options.value.page,
        per_page: options.value.itemsPerPage == -1 ? '0' : options.value.itemsPerPage,
      })
        .then(res => {
          const { data, count, segment, count_of_page } = res
          totalDataTableList.value = count
          totalPage.value = count_of_page
          dataTableList.value = data
          segmentId.value = segment
          loading.value = false
        })
        .catch(err => {
          console.log('get course list error : ', err)
          loading.value = false
        })

    }

  courseGroup.courseGroupList({
    searchtext: '',
    coursegroup_status_id: '1',
  }).then(res => {
    coursegroup_list.value = res
    coursegroup_list.value.unshift({
      coursegroup_id: '',
      coursegroup_name: i18n.t('all'),
    })
  })

  watch([searchtext, coursegroup_id, course_status_id, options], (newvalue, oldvalue) => {
    if (newvalue[0] !== oldvalue[0] || newvalue[1] !== oldvalue[1] || newvalue[2] !== oldvalue[2]) {
      options.value.page = 1
    }
    fetchDataTable()
  })

  return {
    columns,
    dataTableList,
    options,
    searchtext,
    coursegroup_id,
    totalDataTableList,
    footer,
    statusList,
    loading,
    coursegroup_list,
    course_status_id,
    isAddNewCourseSet,
    totalPage,
    isEditCourseSet,
    dataEdit,
    isUpdateStatus,
    segmentId,
    fetchDataTable,
    icons: {
      mdiPlus,
      mdiDeleteOutline, mdiCheck, mdiPencilOutline, mdiInformationOutline,

    },
  }
}
