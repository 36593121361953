<template>
  <div>
    <v-card>
      <v-card-title class='px-2'>
        {{ $t('CourseSetList') }}
        <v-spacer></v-spacer>
        <v-btn
          color='primary'
          class='d-none d-md-block'
          @click='isAddNewCourseSet = true'
        >
          <v-icon class='me-1'>
            {{ icons.mdiPlus }}
          </v-icon>
          {{ $t('AddCourseSetList') }}
        </v-btn>
        <v-btn
          color='primary'
          @click='isAddNewCourseSet = true'
          class='me-2 d-block d-md-none'
          fab
          icon
          outlined
        >
          <v-icon>
            {{ icons.mdiPlus }}
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-row class='px-2'>
        <v-col
          cols='12'
          md='6'
          class='py-0'
          lg='4'
        >
          <v-text-field
            :label="$t('search')"
            v-model='searchtext'
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col
          cols='12'
          md='6'
          class='py-0'
          lg='4'
        >
          <v-select
            v-model.trim='course_status_id'
            :items='statusList'
            :label="$t('status')"
            :item-text='$i18n.locale'
            outlined
            dense
            item-value='value'
          ></v-select>
        </v-col>
      </v-row>
      <v-data-table
        :headers='columns'
        :items='dataTableList'
        :options.sync='options'
        :server-items-length='totalDataTableList'
        :footer-props='footer'
        :loading='loading'
        hide-default-footer
        disable-sort
        :loading-text="$t('data_loading')"
        :no-data-text="$t('no_information')"
      >
        <template v-slot:[`item.id`]='{ index }'>
          {{ segmentId + index + 1 }}
        </template>
        <template v-slot:[`item.courseset_code`]='{ item }'>
          <router-link
            class='font-weight-medium text-decoration-none'
            :to="{ name: 'courseSetDetail', params: { id: item.courseset_id } }"
          >
            {{ item.courseset_code
            }}
          </router-link>
        </template>
        <template v-slot:[`item.courseset_active_id`]='{ item }'>
          <StatusBlock :status='item.courseset_active_id' />
        </template>
        <template v-slot:[`item.courseset_price`]='{ item }'>
          {{ item.courseset_price | formatPrice }}
        </template>
        <template v-slot:[`item.actions`]='{ item }'>
          <router-link
            class='font-weight-medium text-decoration-none'
            :to="{ name: 'courseSetDetail', params: { id: item.courseset_id } }"
          >
            <v-tooltip
              color='#212121'
              top
            >
              <template v-slot:activator='{ on, attrs }'>
                <v-btn
                  icon
                  color='primary'
                  v-bind='attrs'
                  v-on='on'
                >
                  <v-icon>{{ icons.mdiInformationOutline }}</v-icon>
                </v-btn>
              </template>
              {{ $t('detail') }}
            </v-tooltip>
          </router-link>
          <v-tooltip
            color='#212121'
            top
          >
            <template v-slot:activator='{ on, attrs }'>
              <v-btn
                icon
                color='primary'
                v-bind='attrs'
                @click='dataEdit = item; isEditCourseSet = true'
                v-on='on'
              >
                <v-icon>{{ icons.mdiPencilOutline }}</v-icon>
              </v-btn>
            </template>
            {{ $t('edit') }}
          </v-tooltip>
          <v-tooltip
            color='#212121'
            top
          >
            <template v-slot:activator='{ on, attrs }'>
              <v-btn
                icon
                color='primary'
                v-bind='attrs'
                v-on='on'
                @click='dataEdit = item; isUpdateStatus = true'
              >
                <v-icon>{{ item.courseset_active_id == 1 ? icons.mdiDeleteOutline : icons.mdiCheck
                  }}
                </v-icon>
              </v-btn>
            </template>
            {{ item.courseset_active_id == 1 ? $t('suspend') : $t('normal') }}
          </v-tooltip>
        </template>
      </v-data-table>
      <v-divider></v-divider>
      <Pagination
        :page-data='options.page'
        :page-count='totalPage'
        :segment-id='+segmentId '
        :count-list='dataTableList.length'
        :total='+totalDataTableList'
        @pageChanged='(page)=>{options.page = page ; fetchDataTable()}'
        @itemChanged='(items)=>{options.itemsPerPage = items;options.page=1 ; fetchDataTable()}'
      />
    </v-card>
    <AddNewCourseSet v-model='isAddNewCourseSet' @onAdd='fetchDataTable' />
    <EditCourseSet v-model='isEditCourseSet' :dataEdit='dataEdit'
                   @onUpdate='fetchDataTable' />
    <CourseSetUpdateStatus v-model='isUpdateStatus' :data-edit='dataEdit'
                           @update:isUpdateStatus='fetchDataTable' />

  </div>
</template>
<script>
import Pagination from '@/components/basicComponents/Pagination.vue'
import useCourseSetList from '@/views/courseSet/useCourseSetList'
import AddNewCourseSet from './AddNewCourseSet'
import StatusBlock from '@/components/basicComponents/StatusBlock.vue'
import EditCourseSet from '@/views/courseSet/EditCourseSet'
import CourseSetUpdateStatus from '@/views/courseSet/CourseSetUpdateStatus'
import { formatPrice } from '@/plugins/filters'

export default {
  components: {
    CourseSetUpdateStatus,
    AddNewCourseSet,
    Pagination,
    StatusBlock,
    EditCourseSet,
  },
  filters: {
    formatPrice,
  },
  setup() {
    return {
      ...useCourseSetList(),
    }
  },
}
</script>

