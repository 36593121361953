<template>
  <div>
    <v-dialog v-model='isEditCourseSet' persistent max-width='450'>
      <v-card>
        <v-card-title>
          {{ $t('editCourseSet') }}
        </v-card-title>
        <v-form
          ref='formEditCourse'
          @submit.prevent='updateCourseSet'
        >
          <v-card-text>
            <v-text-field
              v-model='dataEditLocal.courseset_name'
              :rules='[required]'
              outlined
              :label="$t('courseSetName')"
              dense
            >
            </v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color='primary'
              type='submit'
              :loading='loading'
              :disabled='loading'
            >
              {{ $t('save') }}
            </v-btn>
            <v-btn
              color='secondary'
              @click="$emit('update:isEditCourseSet',false)"
              outlined
            >
              {{ $t('cancel') }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import { required, textValidation } from '@/@core/utils/validation'
import courseSet from '@/api/courseSet/courseSet'
import store from '@/store'

export default {
  model: {
    prop: 'isEditCourseSet',
    event: 'update:isEditCourseSet',
  },
  props: {
    isEditCourseSet: {
      type: Boolean,
      default: false,
    },
    dataEdit: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props, { emit }) {
    const dataEditLocal = ref({})
    const loading = ref(false)
    const formEditCourse = ref(null)

    const updateCourseSet = () => {
      const isFormValid = formEditCourse.value.validate()
      if (!isFormValid) return
      loading.value = true

      courseSet.courseSetUpdate(dataEditLocal.value).then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
        emit('update:isEditCourseSet', false)
        emit('onUpdate')
        loading.value = false
      }).catch(err => {
        console.log('error : ', err)
        loading.value = false
      })

    }

    watch(() => props.isEditCourseSet, value => {
      if (value) {
        dataEditLocal.value = JSON.parse(JSON.stringify(props.dataEdit))
      }
    })
    return {
      dataEditLocal,
      required,
      updateCourseSet,
      formEditCourse,
      loading,
    }
  },

}
</script>

<style scoped>

</style>
