<template>
  <v-dialog
    v-model='isUpdateStatus'
    persistent
    max-width='350'
  >
    <v-card>
      <v-card-title>
        <v-spacer></v-spacer>
        <v-icon
          color='warning'
          class='me-1'
        >
          {{ icons.mdiAlertOutline }}
        </v-icon>
        {{ localDataEdit.courseset_active_id == 1 ? $t('confirmSuspendCourseSet') : $t('unSuspendCourseSet')
        }}
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color='error'
          :loading='loading'
          :disabled='loading'
          @click='updateStatus'
        >
          {{ $t('confirm') }}
        </v-btn>
        <v-btn
          color='secondary'
          outlined
          @click="$emit('update:isUpdateStatus',false)"
        >
          {{ $t('cancel') }}
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { ref, watch } from '@vue/composition-api'
import { mdiAlertOutline } from '@mdi/js'
import store from '@/store'
import courseSet from '@/api/courseSet/courseSet'

export default {
  name: 'CourseSetUpdateStatus',
  model: {
    prop: 'isUpdateStatus',
    event: 'update:isUpdateStatus',
  },
  props: {
    isUpdateStatus: {
      type: Boolean,
      default: false,
    },
    dataEdit: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props, { emit }) {
    const localDataEdit = ref({})
    const loading = ref(false)

    const updateStatus = () => {
      loading.value = true
      courseSet.courseSetUpdateStatus({
        courseset_id: localDataEdit.value.courseset_id,
        courseset_active_id: localDataEdit.value.courseset_active_id == '1' ? '2' : '1',
      }).then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
        loading.value = false
        emit('update:isUpdateStatus', false)
      }).catch(() => {
        loading.value = false
      })
    }

    watch(() => props.isUpdateStatus, (newVal) => {
      localDataEdit.value = JSON.parse(JSON.stringify(props.dataEdit))
    })

    return {
      localDataEdit,
      loading,
      updateStatus,
      icons: {
        mdiAlertOutline,
      },
    }
  },
}
</script>
