<template>
  <div>
    <v-dialog
      v-model='isAddNewCourseSet'
      persistent
      max-width='450'
    >
      <v-card>
        <v-card-title>
          {{ $t('addCourseSet') }}
          <v-spacer></v-spacer>
        </v-card-title>
        <v-form
          ref='formAddCourse'
          @submit.prevent='createCourse'
        >
          <v-card-text>
            <v-text-field
              v-model='courseset_name'
              :rules='[required]'
              outlined
              :label="$t('courseSetName')"
              dense
            >
            </v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color='primary'
              type='submit'
              :loading='loading'
              :disabled='loading'
            >
              {{ $t('save') }}
            </v-btn>
            <v-btn
              color='secondary'
              @click="$emit('update:isAddNewCourseSet',false)"
              outlined
            >
              {{ $t('cancel') }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import { required, textValidation } from '@/@core/utils/validation'
import courseSet from '@/api/courseSet/courseSet'
import store from '@/store'

export default {
  model: {
    prop: 'isAddNewCourseSet',
    event: 'update:isAddNewCourseSet',
  },
  props: {
    isAddNewCourseSet: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const formAddCourse = ref(null)
    const courseset_name = ref('')
    const loading = ref(false)
    const clearData = () => {
      courseset_name.value = ''
    }


    const createCourse = () => {
      const isFormValid = formAddCourse.value.validate()
      if (!isFormValid) return
      loading.value = true
      courseSet.courseSetAdd({
        courseset_name: courseset_name.value,
      }).then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
        emit('update:isAddNewCourseSet', false)
        emit('onAdd', false)
        loading.value = false
        clearData()
      })
    }


    watch(() => props.isAddNewCourseSet, () => {
      if (props.isAddNewCourseSet) {
        clearData()
      }
    })


    return {
      formAddCourse,
      courseset_name,
      required,
      textValidation,
      createCourse,
      loading,
      clearData,
    }
  },
}
</script>

<style lang='scss' scoped>

</style>
